.about-container{
    margin-top: 35%;
    display: flex;
    flex-direction:column;
    align-content: space-between;
}

.image-div{
    display: flex;
    justify-content:center;
}

.fs-color{
    color:#2E704E;
}

.bio-div{
    margin-left: 0;
    margin-top: 10%;
}

.bio-div h3{
    text-decoration: underline 1px;
    text-decoration-color: #2E704E ;
    text-underline-offset: 2px;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10%;
}

.bio-div p{
    font-size: 20px;
    font-weight: 300;
}

.skills-div{
    margin-left: 0;
    margin-top: 10%;
    margin-bottom: 5%;
}

.skills-div h3{
    font-size: 24px;
    font: 400;
    text-decoration: underline 1px;
    text-decoration-color: #2E704E ;
    text-underline-offset: 2px;
    text-align: center;
    animation: bounce 1s ;
    position: relative;

}

@keyframes bounce {
    0%{
        transform:translateY(-100%);
        opacity: 0;    
    }
    5% {
        transform:translateY(-100%);
        opacity: 0;
    }
    15% {
        transform:translateY(0);
        padding-bottom: 5px;
      }
    30% {
        transform:translateY(-50%);
    }
    40% {
        transform:translateY(0%);
        padding-bottom: 6px;
    }
    50% {
        transform:translateY(-30%);
      }
    70% {
        transform:translateY(0%);
        padding-bottom: 7px;
    }
    80% {
      transform:translateY(-15%);
    }
    90% {
      transform:translateY(0%);
      padding-bottom: 8px;
    }
    95% {
      transform:translateY(-7%);
    }
    97% {
      transform:translateY(0%);
      padding-bottom: 9px;
    }
    99% {
      transform:translateY(-3%);
    }
    100% {
      transform:translateY(0);
      padding-bottom: 9px;
      opacity: 1;
    }

}

.skills-div > ul > li{
    margin-left: 0;
}

.skills-list{
    margin-left: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
}

.spacing{
    margin-bottom: 20px;
}

.skills-list > li{
    width: 145px;
    display: flex;
}

.icon-style{
    height: 60px; 
    margin-right: 4%;
}

.bootstrap-style{
    width: 70px;
    height: 70px;
}


@media (min-width: 65em) {
    .about-container{
        margin-top: 10%
    }
    .profile-image{
        height: 350px;
    }
    .desktop-position{
        display: flex;
        flex-direction: row;
        align-items: center;
    }


    .bio-div{
        margin-top: 5%;
    }

    .bio-div > h3{
        margin-bottom: 5%;
        font-size: 32px;
        width: 50%;
        margin-left: 5%;
    }

    .bio-div > p{
        font-size: 24px;
        margin-left: 5%;
        
    }
    .tablet-tools{
        display: flex;
        justify-content: center;
    }

    .skills-div{
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .skills-list{

        margin-left: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 2em;
        gap: 4.5em;
        width: 50%;
    }
}

@media (min-width: 31em) and (max-width:64em){
    .about-container{
        margin-top: 25%
    }
    .profile-image{
        height: 350px;
    }

    .bio-div{
        margin-top: 5%;
    }

    .bio-div > h3{
        margin-bottom: 5%;
        font-size: 32px;
    }

    .bio-div > p{
        font-size: 24px;
    }
    .tablet-tools{
        display: flex;
        justify-content: center;
    }

    .skills-div{
        margin-top: 5%;
    }

    .skills-list{
        margin-left: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 2em;
        gap: 4.5em;
    }
}