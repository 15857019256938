@media (min-width:64em){


    .tracking-in-contract {
        -webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }

    @-webkit-keyframes tracking-in-contract {
    0% {
        letter-spacing: 1em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        letter-spacing: normal;
        opacity: 1;
    }
    }
    @keyframes tracking-in-contract {
    0% {
        letter-spacing: 1em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        letter-spacing: normal;
        opacity: 1;
    }
    }

    .tracking-in-expand-fwd-bottom {
        -webkit-animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }

    @-webkit-keyframes tracking-in-expand-fwd-bottom {
        0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px) translateY(500px);
                transform: translateZ(-700px) translateY(500px);
        opacity: 0;
        }
        40% {
        opacity: 0.6;
        }
        100% {
        -webkit-transform: translateZ(0) translateY(0);
                transform: translateZ(0) translateY(0);
        opacity: 1;
        }
    }
    @keyframes tracking-in-expand-fwd-bottom {
        0% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-700px) translateY(500px);
                transform: translateZ(-700px) translateY(500px);
        opacity: 0;
        }
        40% {
        opacity: 0.6;
        }
        100% {
        -webkit-transform: translateZ(0) translateY(0);
                transform: translateZ(0) translateY(0);
        opacity: 1;
        }
    }
    


/* 
    .tracking-in-contract-bck-bottom {
        -webkit-animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }

    @-webkit-keyframes tracking-in-contract-bck-bottom {
        0% {
        letter-spacing: 1em;
        -webkit-transform: translateZ(400px) translateY(300px);
                transform: translateZ(400px) translateY(300px);
        opacity: 0;
        }
        40% {
        opacity: 0.6;
        }
        100% {
        -webkit-transform: translateZ(0) translateY(0);
                transform: translateZ(0) translateY(0);
        opacity: 1;
        }
    }
    @keyframes tracking-in-contract-bck-bottom {
        0% {
        letter-spacing: 1em;
        -webkit-transform: translateZ(400px) translateY(300px);
                transform: translateZ(400px) translateY(300px);
        opacity: 0;
        }
        40% {
        opacity: 0.6;
        }
        100% {
        -webkit-transform: translateZ(0) translateY(0);
                transform: translateZ(0) translateY(0);
        opacity: 1;
        }
    } */
    



/* 
    .focus-in-expand {
        -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }


    @-webkit-keyframes focus-in-expand {
        0% {
        letter-spacing: -0.5em;
        -webkit-filter: blur(12px);
                filter: blur(12px);
        opacity: 0;
        }
        100% {
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
        }
    }
    @keyframes focus-in-expand {
        0% {
        letter-spacing: -0.5em;
        -webkit-filter: blur(12px);
                filter: blur(12px);
        opacity: 0;
        }
        100% {
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
        }
    } */
  

/* 
    .text-pop-up-top {
        -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes text-pop-up-top {
        0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        text-shadow: none;
        }
        100% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
        }
    }
    @keyframes text-pop-up-top {
        0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        text-shadow: none;
        }
        100% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
        }
    } */
  
    

/* 
    .text-flicker-in-glow {
        -webkit-animation: text-flicker-in-glow 4s linear both;
                animation: text-flicker-in-glow 4s linear both;
    }

    @-webkit-keyframes text-flicker-in-glow {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 0;
          text-shadow: none;
        }
        10.1% {
          opacity: 1;
          text-shadow: none;
        }
        10.2% {
          opacity: 0;
          text-shadow: none;
        }
        20% {
          opacity: 0;
          text-shadow: none;
        }
        20.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
        }
        20.6% {
          opacity: 0;
          text-shadow: none;
        }
        30% {
          opacity: 0;
          text-shadow: none;
        }
        30.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.5% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.6% {
          opacity: 0;
          text-shadow: none;
        }
        45% {
          opacity: 0;
          text-shadow: none;
        }
        45.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        50% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55.1% {
          opacity: 0;
          text-shadow: none;
        }
        57% {
          opacity: 0;
          text-shadow: none;
        }
        57.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60.1% {
          opacity: 0;
          text-shadow: none;
        }
        65% {
          opacity: 0;
          text-shadow: none;
        }
        65.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75.1% {
          opacity: 0;
          text-shadow: none;
        }
        77% {
          opacity: 0;
          text-shadow: none;
        }
        77.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85.1% {
          opacity: 0;
          text-shadow: none;
        }
        86% {
          opacity: 0;
          text-shadow: none;
        }
        86.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        100% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
      }
      @keyframes text-flicker-in-glow {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 0;
          text-shadow: none;
        }
        10.1% {
          opacity: 1;
          text-shadow: none;
        }
        10.2% {
          opacity: 0;
          text-shadow: none;
        }
        20% {
          opacity: 0;
          text-shadow: none;
        }
        20.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
        }
        20.6% {
          opacity: 0;
          text-shadow: none;
        }
        30% {
          opacity: 0;
          text-shadow: none;
        }
        30.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.5% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.6% {
          opacity: 0;
          text-shadow: none;
        }
        45% {
          opacity: 0;
          text-shadow: none;
        }
        45.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        50% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55.1% {
          opacity: 0;
          text-shadow: none;
        }
        57% {
          opacity: 0;
          text-shadow: none;
        }
        57.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60.1% {
          opacity: 0;
          text-shadow: none;
        }
        65% {
          opacity: 0;
          text-shadow: none;
        }
        65.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75.1% {
          opacity: 0;
          text-shadow: none;
        }
        77% {
          opacity: 0;
          text-shadow: none;
        }
        77.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85.1% {
          opacity: 0;
          text-shadow: none;
        }
        86% {
          opacity: 0;
          text-shadow: none;
        }
        86.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        100% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
      }
      

 */


}