@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&display=swap');


* {
  background-color: #EDEEF0;
  margin-left: 1.2%;
  margin-right: 1%;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial;
 
}
