@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  background-color: #EDEEF0;
  margin-left: 1.2%;
  margin-right: 1%;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial;
 
}



.main-container{
    margin-top: 23%;
    z-index: 2; 
}

.fs-style{
    color:#2E704E;
}

.first-container{
    margin-left: -1%;
}

.first-container h2{
    font-size: 30px;
    font-weight: 300;
}

.first-container > h3{
    font-size: 24px;
    font-weight: 300;
}

.image-container{
    margin-top: 7%;
    display: flex;
    justify-content: center;
}

.profile-image{
    border-radius: 50%;
    height: 275px;
}

.contact-me-form{
    height: 77vh;
}


.button-position{
    display:flex;
    justify-content: center;
    margin: 12%;
}

.contact-btn{
    box-shadow: 2px 2px 2px rgb(167, 167, 167);
    background-color: #2E704E;
    height: 68px;
    width: 200px;
    border: 0;
    border-radius: 0 20px 0 20px;
    font-size: 24px;
    color: #EDEEF0;
}

.last-container > p{
    margin-top: 10%;
    font-size: 22px;
    font-weight: 300;
}


/* MEDIA IS A COMPUTER*/

@media (min-width:64em){


    .main-container{
        margin-top: 5%;
    }

    .sub-container{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-evenly;
    }
    .sub-container > .first-container{
        margin-left: 3%;
    }

    .last-container > p{
        margin-top: 5%;
        font-size: 25px;
        width: 100%;
    }

    .button-position{
        margin: 5%;
    }

    .recent-work{
        margin-top: 10%;
    }
    .inner-recent-work{
        display: flex;
        justify-content: space-evenly;
    }
    .recent-work > h3{
        font-size: 28px;
        color: #111818;
        text-decoration: underline;
        -webkit-text-decoration-color: #2E704E;
                text-decoration-color: #2E704E;
        text-underline-offset: 2px;
        text-align: center;
        margin-bottom:5%;
    }

    .profile-image{
        height: 350px;
    }

    .card-img{
        height:180px;
    }

    .main-card-style{
        width: 328.5px;
        margin-left: 2%;
    }



    .buyback-card > p{
        font-size: 32px;
        margin-top: -2%;
        padding-top: 2%;
        width: 100%;
        text-align: center;
        background-color: #2E704E;
    }


    .buyback-card > div{
        background-color: #2E704E;
        width: 100%;
        padding-top: 5%;
        margin-top:-1%;
        margin-right: 3px;
        color: #EDEEF0;
        height: 133px;
    }

    .buyback-card > div > p{
        background-color: #2E704E;
        margin: 2%;
    }


    .buyback-card > p{
        background-color: #2E704E;
        color: #EDEEF0;
        margin-bottom: 0;
    }  
 
    
    
    .kingtrader-card > p{
        background-color: #2E704E;
        color: #EDEEF0;
    }
    .buyback-tools{
        width: 320px;
    }

    .buyback-tools > h3{
        background-color: #2E704E;
        font-size: 22px;
        font-weight: 300;
        margin-left: 2%;

    }

   
    .lower-card{
        background-color: #2E704E;
    }

    .home-section{
        height: 100px;
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        padding: 2%;
    }

    .kt-home-section{
        height: 100px;
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
    }

    .home-styles{
        background-color: #2E704E;
        height: 40px;
    }

    .first-container > h3{
        font-size: 26px;
    }

    

}



/* MEDIA LESS THAN A PHONE SIZE */

@media (max-width: 30em) {
    .recent-work{
        display: none;
    }
}


/* MEDIA REGULAR TABLET */

@media (min-width: 31em) and (max-width:48em){



    .styles_c__1wf9-{
        display:none;
    }
    
    .styles_c__2JQH2{
        display:none;
    }

    .styles_c__6saF9{
        display:none;
    }

    .main-container{
        margin-top:15%;
    }

    .sub-container{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .sub-container > .first-container{
        margin-left: 3%;
    }

    .last-container > p{
        margin-top: 5%;
        font-size: 25px;
        width: 100%;
    }

    .button-position{
        margin: 5%;
    }

    .recent-work{
        margin-top: 10%;
    }
    .inner-recent-work{
        display: flex;
        justify-content: space-between;
    }
    .recent-work > h3{
        font-size: 28px;
        color: #111818;
        text-decoration: underline;
        -webkit-text-decoration-color: #2E704E;
                text-decoration-color: #2E704E;
        text-underline-offset: 2px;
        text-align: center;
        margin-bottom:5%;
    }

    .card-img{
        height:173.5px;
    }

    .main-card-style{
        width: 316px;
        margin-left: 2%;
        margin-top: 3vh;
    }

    .first-container > h3{
        font-size: 26px;
    }

   


}


@media (min-width: 49em) and (max-width:64em){


    .main-container{
        margin-top:15%;
    }

    .sub-container{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .sub-container > .first-container{
        margin-left: 3%;
    }

    .last-container > p{
        margin-top: 5%;
        font-size: 25px;
        width: 100%;
    }

    .button-position{
        margin: 5%;
    }

    .recent-work{
        margin-top: 10%;
    }
    .inner-recent-work{
        display: flex;
        justify-content: space-between;
    }
    .recent-work > h3{
        font-size: 28px;
        color: #111818;
        text-decoration: underline;
        -webkit-text-decoration-color: #2E704E;
                text-decoration-color: #2E704E;
        text-underline-offset: 2px;
        text-align: center;
        margin-bottom:5%;
    }

}
header > a{
    margin-left: 0;
    margin-top: 1%;
    font-weight: 400;
    font-size: 24px;
    color: #111818;
}

li{
    list-style: none;
}

a{
    text-decoration: none;
}

.nav-logo{
  font-size: 28px;
  margin-left: 5%;
}

.linkedIn{
    height: 50px;
    border-radius: 50%;
}

.github{
    height: 50px;
}

.resume-image{
    height: 50px;
}

.resume-title{
    font-size:12px;
    margin-top:0;
    text-underline-offset: 5px;
}
.menu{
  z-index: 3;
}

.menu-links{
    display: flex;
    justify-content: center;
}

.header {
  padding-top: 5%;
  padding-bottom: 6%;
  margin-left: -7%;
  width: 102%;
  top: 0;
  z-index: 3;
  position: fixed;  
  overflow-y: hidden;
  /* margin-top: env(safe-area-inset-top);  */

}


  .header ul{
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
  
  .header li a {
    display: block;
    padding: 20px 20px;
    margin-bottom: 20%;
    margin-top: 5%;
    font-size: 36px;
    color: #2E704E;   
    -webkit-text-decoration: underline 1px !important;   
            text-decoration: underline 1px !important;
    -webkit-text-decoration-line: underline 1px !important;
    text-underline-offset: 10px !important;
    -webkit-text-decoration-color: #111818;
            text-decoration-color: #111818;
  }


  .header .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px;
    text-decoration: none;
  }
  
  
  /* menu */


  
  .header .menu {
    clear: both;
    height: 0;
    transition: max-height .0s ease-in-out;
  }
  
  /* menu icon */
  
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    margin-top: 12px;
    margin-right: 5%;
    float: right;
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: 20px;
    
  }
  
  .header .menu-icon .navicon {
    background: #2E704E;
    border-radius: 3px;
    display: block;
    height: 4px;
    position: relative;
    width: 22px;
    margin-right: 2%;
    transform: scaleX(-1);
    margin-bottom: 5px;
    margin-top: 5px;
  }


  
  .header .menu-icon .navicon:before{
    background: #111818;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.3s ease-out;
  }

  .header .menu-icon .navicon:after {

    background: #111818;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.3s ease-out;

  }
  
  .header .menu-icon .navicon:before {
    top: 10px;
    color: #2E704E;
    width:34px;
    border-radius: 0px 3px 0px 3px;
  }
  
  .header .menu-icon .navicon:after {
    top: -10px;
    color: #111818;
    border-radius: 3px 0px 3px 0px;
    width: 26px;
  }
  
  /* menu btn */
  
  .header .menu-btn {
    display: none;
  }



  .header .menu-btn:checked ~ .menu {
    height: 56vh;  
    text-align: center;
    font-size: 18px;
    margin-right: -1%;
    margin-top: 5%;
    -webkit-text-decoration: underline 1px !important;
            text-decoration: underline 1px !important;
    text-underline-offset: 10px !important;
    -webkit-text-decoration-color: #111818;
            text-decoration-color: #111818;
  }




  .header .menu-btn:checked ~ .menu ul li {
      margin-bottom: 5%;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
    width: 32px;
  }
  
  .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
    width: 32px;
    background-color: #2E704E;
  }
  
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;

  }



  @media (min-width:64em){
    .header{
      height: 8vh; 
      overflow-y: none;
      padding-top: 30px;
      padding-bottom: 3%;
    }
    .menu-links{
      display: none;
    }
    .header ul{
      margin-top: -1.8%;
      margin-right: 2%;
    }


    
    .header li a{
      font-size: 24px;
      padding: 0;
      margin: 0;
    }
    .header .menu{
      top:0;
      margin-bottom: 0;
      width: 40%;
      height: 70px;
    }
  }
  
  /* 48em = 768px */

  @media (min-width: 31em) and (max-width:49em){
 
    .header{
      
      height: 8vh; 
      overflow-x: none;
      max-width: 100%;
      overflow-x: hidden;

    }
    .header ul{
      margin-top: -1.8%;
      margin-right: -2%;
    }
    

    .header li a{
      font-size: 20px;
      padding: 0;
      margin: 0;
    }
    .nav-logo{
      margin-top: 2%;
      height:62px;
      margin-left: 5%;
    }

    .menu-links{
      display: none;
    }
    .menu{
      top:0;
      margin-bottom: 0;
      width :70%;
    }


    .header .menu-icon .navicon {
      display: none;
    }
  }





  @media (min-width: 49em) and (max-width:64em){
    .header{
      height: 8vh; 
      overflow-y: auto;
    }
    .header ul{
      margin-top: -1.8%;
      margin-right: 5%;
    }
    

    .header li a{
      font-size: 22px;
      padding: 0;
      margin: 0;
    }
    .nav-logo{
      margin-top: 2%;
      height:62px;
      margin-left: 5%;
    }

    .menu-links{
      display: none;
    }
    .menu{
      top:0;
      margin-bottom: 0;
      width :70%;
    }

    .menu > li{
      font-size:24px;
    }

    .header .menu-icon .navicon {
      display: none;
    }
  }



  
  @media (min-width: 30em) {
    .header li {
      float: left;
    }
    .header li a {
      padding: 20px 30px;
    }
    .header .menu {
      clear: none;
      float: right;
      max-height: none;
      margin-bottom: 300px;
      height: 70px;
    }
    .header .menu-icon {
      display: none;
    }

  }
  


.footer-section{
    display: flex;
    flex-direction: column;
    margin: 5% 2% 0 2%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;
    z-index: 0;
    background-color: #EDEEF0;
}


.linkedIn-footer{
    height: 40px
}

.github-footer{
    height: 40px

}

.resume-image-footer{
    height: 40px
}

.div-footer-list{
    margin-bottom: 0;
}

.footer-list{
    margin-bottom: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-list > li > a{
    color: #111818;
    font-size: 18px;
    font-weight: bold;
}

.profile-links{
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 30em) {
    .footer-section{
        display: none;
    }
}

.buyback__mainContainer > *{
    background-color: #fff;
}

.buyback__mainContainer{
    box-shadow: 2px 2px 2px rgb(167, 167, 167);
    border-radius: 25px;
    background-color: #fff;
    height: 588px;
    width: 316px;
    z-index: 0;
}

.buyback__ShapeGradient{
    margin: 0;
    margin-left: 5px;
    background: linear-gradient(170deg, rgba(245,124,1,1) 0%, rgba(255,255,255,1) 100%);   
    height: 209px;
    width: 311px;
    z-index: -1;
    border-radius: 20px 25px 0 100%;
    position: absolute;

}

.buyback__SampleImage{
    background-color: transparent;
    height: 135px;
    width: 185px;
    margin-left: 30px;
    margin-top:20px;
}

.buyback__title{
    margin: 0;
    margin-top: 11px;
    top: 0;
    text-align:center;
    font-size: 24px;
    font-weight: 400;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 4px;
    z-index: 10;
    background-color: transparent;
}

.buyback__description{    
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
}

.buyback__tools > *{
    background-color: #fff;
}

.buyback__tools{
    margin: 0;
}

.buyback__toolTitle{
    font-size: 16px;
    margin-left: 20px;
    font-weight: 300;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 2px;
}

.buyback__toolIcons{
    margin-left: 20px;
    margin-right: 20px;
}

.buyback__toolIcons > * {
    margin-bottom: 10px;
    margin-right: 20px;
    width: 40px;
    background-color: #fff;
}

.buyback__demoLink{
    display: flex;
    justify-content: center;
}

.buyback__demoButton{
    height: 37px;
    width: 121px;
    background-color: #479e71;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    margin-top:15px;
}
@media (min-width:64em){


    .tracking-in-contract {
        animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }
    @keyframes tracking-in-contract {
    0% {
        letter-spacing: 1em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        letter-spacing: normal;
        opacity: 1;
    }
    }

    .tracking-in-expand-fwd-bottom {
        animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }
    @keyframes tracking-in-expand-fwd-bottom {
        0% {
        letter-spacing: -0.5em;
        transform: translateZ(-700px) translateY(500px);
        opacity: 0;
        }
        40% {
        opacity: 0.6;
        }
        100% {
        transform: translateZ(0) translateY(0);
        opacity: 1;
        }
    }
    


/* 
    .tracking-in-contract-bck-bottom {
        -webkit-animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }

    @-webkit-keyframes tracking-in-contract-bck-bottom {
        0% {
        letter-spacing: 1em;
        -webkit-transform: translateZ(400px) translateY(300px);
                transform: translateZ(400px) translateY(300px);
        opacity: 0;
        }
        40% {
        opacity: 0.6;
        }
        100% {
        -webkit-transform: translateZ(0) translateY(0);
                transform: translateZ(0) translateY(0);
        opacity: 1;
        }
    }
    @keyframes tracking-in-contract-bck-bottom {
        0% {
        letter-spacing: 1em;
        -webkit-transform: translateZ(400px) translateY(300px);
                transform: translateZ(400px) translateY(300px);
        opacity: 0;
        }
        40% {
        opacity: 0.6;
        }
        100% {
        -webkit-transform: translateZ(0) translateY(0);
                transform: translateZ(0) translateY(0);
        opacity: 1;
        }
    } */
    



/* 
    .focus-in-expand {
        -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }


    @-webkit-keyframes focus-in-expand {
        0% {
        letter-spacing: -0.5em;
        -webkit-filter: blur(12px);
                filter: blur(12px);
        opacity: 0;
        }
        100% {
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
        }
    }
    @keyframes focus-in-expand {
        0% {
        letter-spacing: -0.5em;
        -webkit-filter: blur(12px);
                filter: blur(12px);
        opacity: 0;
        }
        100% {
        -webkit-filter: blur(0px);
                filter: blur(0px);
        opacity: 1;
        }
    } */
  

/* 
    .text-pop-up-top {
        -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes text-pop-up-top {
        0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        text-shadow: none;
        }
        100% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
        }
    }
    @keyframes text-pop-up-top {
        0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        text-shadow: none;
        }
        100% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
        }
    } */
  
    

/* 
    .text-flicker-in-glow {
        -webkit-animation: text-flicker-in-glow 4s linear both;
                animation: text-flicker-in-glow 4s linear both;
    }

    @-webkit-keyframes text-flicker-in-glow {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 0;
          text-shadow: none;
        }
        10.1% {
          opacity: 1;
          text-shadow: none;
        }
        10.2% {
          opacity: 0;
          text-shadow: none;
        }
        20% {
          opacity: 0;
          text-shadow: none;
        }
        20.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
        }
        20.6% {
          opacity: 0;
          text-shadow: none;
        }
        30% {
          opacity: 0;
          text-shadow: none;
        }
        30.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.5% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.6% {
          opacity: 0;
          text-shadow: none;
        }
        45% {
          opacity: 0;
          text-shadow: none;
        }
        45.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        50% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55.1% {
          opacity: 0;
          text-shadow: none;
        }
        57% {
          opacity: 0;
          text-shadow: none;
        }
        57.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60.1% {
          opacity: 0;
          text-shadow: none;
        }
        65% {
          opacity: 0;
          text-shadow: none;
        }
        65.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75.1% {
          opacity: 0;
          text-shadow: none;
        }
        77% {
          opacity: 0;
          text-shadow: none;
        }
        77.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85.1% {
          opacity: 0;
          text-shadow: none;
        }
        86% {
          opacity: 0;
          text-shadow: none;
        }
        86.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        100% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
      }
      @keyframes text-flicker-in-glow {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 0;
          text-shadow: none;
        }
        10.1% {
          opacity: 1;
          text-shadow: none;
        }
        10.2% {
          opacity: 0;
          text-shadow: none;
        }
        20% {
          opacity: 0;
          text-shadow: none;
        }
        20.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
        }
        20.6% {
          opacity: 0;
          text-shadow: none;
        }
        30% {
          opacity: 0;
          text-shadow: none;
        }
        30.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.5% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.6% {
          opacity: 0;
          text-shadow: none;
        }
        45% {
          opacity: 0;
          text-shadow: none;
        }
        45.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        50% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55.1% {
          opacity: 0;
          text-shadow: none;
        }
        57% {
          opacity: 0;
          text-shadow: none;
        }
        57.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60.1% {
          opacity: 0;
          text-shadow: none;
        }
        65% {
          opacity: 0;
          text-shadow: none;
        }
        65.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75.1% {
          opacity: 0;
          text-shadow: none;
        }
        77% {
          opacity: 0;
          text-shadow: none;
        }
        77.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85.1% {
          opacity: 0;
          text-shadow: none;
        }
        86% {
          opacity: 0;
          text-shadow: none;
        }
        86.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        100% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
      }
      

 */


}
.igClone__mainContainer > *{
    background-color: #fff;
}

.igClone__mainContainer{
    box-shadow: 2px 2px 2px rgb(167, 167, 167);
    border-radius: 25px;
    background-color: #fff;
    height: 588px;
    width: 316px;
    z-index: 0;
}

.igClone__shapeGradient{
    margin: 0;
    background: linear-gradient(170deg, rgba(186,186,188,1) 0%, rgba(255,255,255,1) 100%);
    height: 209px;
    width: 316px;
    border-radius: 25px 25px 100% 100%;
    position: absolute;
    z-index: -1;
}

.igClone__SampleImage{
    background-color: transparent;
    height: 135px;
    width: 185px;
    margin-left: 30px;
    margin-top:20px;
}

.igClone__title{
    margin: 0;
    margin-top: 11px;
    top: 0;
    text-align:center;
    font-size: 24px;
    font-weight: 400;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 4px;
    z-index: 10;
    background-color: transparent;
}

.igClone__description{    
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
}

.igClone__tools > *{
    background-color: #fff;
}

.igClone__tools{
    margin: 0;
}

.igClone__toolTitle{
    font-size: 16px;
    margin-left: 20px;
    font-weight: 300;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 2px;
}

.igClone__toolIcons{
    margin-left: 20px;
    margin-right: 20px;
}

.igClone__toolIcons > * {
    margin-bottom: 10px;
    margin-right: 20px;
    width: 40px;
    background-color: #fff;
}

.igClone__demoLink{
    display: flex;
    justify-content: center;
}

.igClone__demoButton{
    height: 37px;
    width: 121px;
    background-color: #479e71;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    margin-top:15px;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}


.buyback-image{
    background-image: url(/static/media/BuyBack.2c38b1d8.png);
    background-size:contain;
    background-repeat: no-repeat;
    height: 200px;
    width:350px;
    

}



.title{
  background-color: transparent;
  height: 200px;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  color: #BABABC;
  padding-top: 42%;
}
.title > p{
  margin-top: 0;
  font-size: 14px;
  background-color: transparent ;
}


.buyback-back{
    background-color: #F57C01;
    height: 200px;
    width: 350px;
}

.buyback-back h3{
  background-color: transparent;
  color: #EDEEF0;
  margin-bottom:-1%;
}

.a-tag{
  background-color: transparent;
  color: #EDEEF0;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.description-p{
  background-color: transparent;
  color: #EDEEF0;
  font-size: 12px;
}

.lower-deets{
  margin-top: -2%;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height:53px;
  background-color: #F57C01;
}

.duration  ,  section{
  background-color: #2E704E;
  margin-top: 0;
  margin-left: 0;
  color: #EDEEF0;
  font-size: 10px;
}

.duration{
  margin-top: 7%;
}

.duration > h4{
  background-color: #F57C01;
  margin-bottom:5%;
  font-size: 12px;
  margin-top: 0;
 
}

.duration > section{
  margin-left: 3px;
}

.buyback__duration{
  background-color: #F57C01;
  margin-bottom:5%;
  font-size: 12px;
  margin-top: 0;
}

.buyback__duration > *{
  background-color: #F57C01;
  color: #EDEEF0;
}

.tool-section{
  background-color: #F57C01;
  width: 50%;
}

.tool-section > *{
  background-color: #F57C01;
}

.tool-section > * > * {
  background-color: #F57C01;
  height: 50px;
}
.tool-section > h4{
  margin-bottom: 2%;
  color:#EDEEF0;
  text-decoration: underline;
  text-underline-offset: 2px;
  margin-top: 0;
}

.tool-list{
  background-color: #F57C01;
  height: 50px;
  width: 150px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}


.styles_c__2JQH2 {
    margin-top: 90%;
    position: absolute;
    max-width: 500px;
    max-height: 500px;
    height: 200px;
    cursor: pointer;
    will-change: transform, opacity;
  }
  
  .styles_front__eNKN-,
  .styles_back__3VFO9 {
    background-size: cover;
  }
  

  
  .styles_container__2hvUS {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
  }
  

*,
*:after,
*:before {
  box-sizing: border-box;
}

.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}

.kingtrader-image{
  background-image: url(/static/media/KingTrader.0e8056ae.png);
  background-size:contain;
  background-repeat: no-repeat;
  height: 200px;
  width:350px;
}

.kingtrader-back{
    background-color: #2E704E;
    height: 200px;
    width: 350px;
}

.kingtrader-back h3{
  background-color: transparent;
  margin-bottom: 0;
}

.kt-a-tag{
  background-color: transparent;
  color: #111818;
  text-decoration: underline;
  text-underline-offset: 2px;
}


.kingtrader-title{
  padding-top: 41%;
  color: #00a851;

}
 .kingtrader-title > p{
   margin-top: 0;
   font-size: 14px;
   background-color: transparent ;
 }

.kt-description-p{
  background-color: transparent;
  color: #111818;
  font-size: 12px;
  margin-top: 1%;
}

.kt-lower-deets{
  margin-top: 0;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
}

.kt-lower-deets{
  margin-top: -7%;
  background-color: #2E704E;
  height: 53px;
}

.kt-duration {
  background-color: #2E704E;
  margin-top: 14%;
  margin-left: 0;
  margin-bottom:5%;
}

.kt-duration > h4{
  background-color: #2E704E;
  color: #111818;
  margin-bottom:5%;
  margin-top:0;
  font-size: 12px;
}

.kt-duration section{
  color: #111818;
  background-color: #2E704E;
}




.kt-tool-section{
  background-color: #2E704E;
}

.kt-tool-section > *{
  background-color: #2E704E;
}

.kt-tool-section > * > * {
  background-color: #2E704E;
  height: 50px;
}
.kt-tool-section > h4{
  margin-bottom: 2%;
  color:#111818;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.kt-tool-list{
  background-color: #2E704E;
  height: 50px;
  width: 168px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.icon-styles{
  margin-top:1%;
  height:22px;
}

.header__stylePhone{
  display: flex;
  justify-content: space-between;
}
.styles_c__1wf9- {
    margin-top: 270%;
    position: absolute;
    max-width: 500px;
    max-height: 500px;
    height: 200px;
    cursor: pointer;
    will-change: transform, opacity;
  }
  
  .styles_front__Pybvv,
  .styles_back__1NExu {
    background-size: cover;
  }
  

  
  .styles_container__1kvBo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
  }
  

*,
*:after,
*:before {
  box-sizing: border-box;
}

.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}

.thePotterSaga-image{
  background-image: url(/static/media/harry-potter-saga.a7193aa4.png);
  background-size:contain;
  background-repeat: no-repeat;
  height: 200px;
  width:350px;
}

.thePotterSaga-back{
    background-color:khaki;
    height: 200px;
    width: 350px;
}

.thePotterSaga-back h3{
  background-color: khaki;
  margin-bottom: 0;
}

.thePotterSaga-a-tag{
  background-color: transparent;
  color: #111818;
  text-decoration: underline;
  text-underline-offset: 2px;
}


.thePotterSaga-title{
  padding-top: 40%;
  color: #00ffc8;
}
.thePotterSaga-title > p{
  color: #00ffc8;
}

.thePotterSaga-description-p{
  background-color: khaki;
  color: #111818;
  font-size: 12px;
  margin-top: 1%;
}

.thePotterSaga-lower-deets{
  margin-top: 0;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
}

.thePotterSaga-lower-deets{
  margin-top: -7%;
  background-color: khaki;
  height: 53px;
}

.thePotterSaga-duration {
  background-color: khaki;
  margin-top: 14%;
  margin-left: 0;
  margin-bottom:5%;
}

.thePotterSaga-duration > h4{
  background-color: khaki;
  color: #111818;
  margin-bottom:5%;
  margin-top:0;
  font-size: 12px;
}

.thePotterSaga-duration section{
  color: #111818;
  background-color: khaki;
}




.thePotterSaga-tool-section{
  background-color: khaki;
  margin-top: 2.5vh;
}

.thePotterSaga-tool-section > *{
  background-color: khaki;
}

.thePotterSaga-tool-section > * > * {
  background-color: khaki;
  height: 50px;
}
.thePotterSaga-tool-section > h4{
  margin-bottom: 2%;
  color:#111818;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.thePotterSaga-tool-list{
  background-color: khaki;
  height: 50px;
  width: 168px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.icon-styles{
  margin-top:1%;
  height:22px;
}
.styles_c__6saF9 {
    margin-top: 210%;
    position: absolute;
    max-width: 500px;
    max-height: 500px;
    height: 200px;
    cursor: pointer;
    will-change: transform, opacity;
  }
  
  .styles_front__3bok9,
  .styles_back__2vaNg {
    background-size: cover;
  }
  

  
  .styles_container__16hzh {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
  }
  

*,
*:after,
*:before {
  box-sizing: border-box;
}

.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}

.igClone-image{
  background-image: url(/static/media/igClone.8190a683.png);
  background-size:contain;
  background-repeat: no-repeat;
  height: 200px;
  width:350px;
}

.igClone-back{
    background-color: #bebcbc;
    height: 200px;
    width: 350px;
}

.igClone-back h3{
  background-color: transparent;
  margin-bottom: 0;
}

.igClone-a-tag{
  background-color: transparent;
  color: #111818;
  text-decoration: underline;
  text-underline-offset: 2px;
}


.igClone-title{
  padding-top: 41%;
  color: #111818;
}
 .igClone-title > p{
   margin-top: 0;
   font-size: 14px;
   background-color: transparent ;
   color: #111818;
 }

.igClone-description-p{
  background-color: transparent;
  color: #111818;
  font-size: 12px;
  margin-top: 1%;
}

.igClone-lower-deets{
  margin-top: 0;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
}

.igClone-lower-deets{
  margin-top: -7%;
  background-color: #bebcbc;
  height: 53px;
}

.igClone-duration {
  background-color: #bebcbc;
  margin-top: 14%;
  margin-left: 0;
  margin-bottom:5%;
}

.igClone-duration > h4{
  background-color: #bebcbc;
  color: #111818;
  margin-bottom:5%;
  margin-top:0;
  font-size: 12px;
}

.igClone-duration section{
  color: #111818;
  background-color: #bebcbc;
}




.igClone-tool-section{
  background-color: transparent;
  margin: 0;
}

.igClone-tool-section > *{
  background-color: transparent;
}

.igClone-tool-section > * > * {
  background-color: transparent;
  height: 50px;
}
.igClone-tool-section > h4{
  margin-bottom: 2%;
  color:#111818;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.igClone-tool-list{
  background-color: #bebcbc;
  height: 40px;
  width: 168px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.icon-styles{
  margin-top:1%;
  height:22px;
}

.live_styles_phone{
  text-decoration: none;
  font-size: 14px;
  padding: 3px;
  border: 1px solid white;
  margin-right: 15px;
  background-color: transparent;
}





.styles_c__3cqBa {
    margin-top: 150%;
    position: absolute;
    max-width: 500px;
    max-height: 500px;
    height: 200px;
    cursor: pointer;
    will-change: transform, opacity;
  }
  
  .styles_front__1V5ci,
  .styles_back__23_Rl {
    background-size: cover;
  }
  

  
  .styles_container__uP2Oo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
  }
  

*,
*:after,
*:before {
  box-sizing: border-box;
}

.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}

.pt-image{
  border: 1px solid #6081a3;
  background-image: url(/static/media/pt.7e5d0dcb.svg);
  background-size:contain;
  background-repeat: no-repeat;
  height: 200px;
  width:350px;
}

.pt-back{
    background-color: #6081a3;
    height: 200px;
    width: 350px;
}

.pt-back h3{
  background-color: transparent;
  margin-bottom: 0;
}

.pt-a-tag{
  background-color: transparent;
  color: #111818;
  text-decoration: underline;
  text-underline-offset: 2px;
}


.pt-title{
  padding-top: 41%;
  color: #111818;
}
 .pt-title > p{
   margin-top: 0;
   font-size: 14px;
   background-color: transparent ;
   color: #111818;
 }

.pt-description-p{
  background-color: transparent;
  color: #111818;
  font-size: 12px;
  margin-top: 1%;
}

.pt-lower-deets{
  margin-top: 0;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
}

.pt-lower-deets{
  margin-top: -7%;
  background-color: #6081a3;
  height: 53px;
}

.pt-duration {
  background-color: #6081a3;
  margin-top: 14%;
  margin-left: 0;
  margin-bottom:5%;
}

.pt-duration > h4{
  background-color: #6081a3;
  color: #111818;
  margin-bottom:5%;
  margin-top:0;
  font-size: 12px;
}

.pt-duration section{
  color: #111818;
  background-color: #6081a3;
}




.pt-tool-section{
  background-color: transparent;
  margin: 0;
}

.pt-tool-section > *{
  background-color: transparent;
}

.pt-tool-section > * > * {
  background-color: transparent;
  height: 50px;
}
.pt-tool-section > h4{
  margin-bottom: 2%;
  color:#111818;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.pt-tool-list{
  background-color: #6081a3;
  height: 40px;
  width: 168px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.icon-styles{
  margin-top:1%;
  height:22px;
}

.live_styles_phone{
  text-decoration: none;
  font-size: 14px;
  padding: 3px;
  border: 1px solid white;
  margin-right: 15px;
  background-color: transparent;
}





.styles_c__1d5Tj {
    margin-top: 25%;
    position: absolute;
    max-width: 500px;
    max-height: 500px;
    height: 200px;
    cursor: pointer;
    will-change: transform, opacity;
  }
  
  .styles_front__1WMl6,
  .styles_back__37HzX {
    background-size: cover;
  }
  

  
  .styles_container__13W2K {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
  }
  
.container-flex{
    height: 100vh;
}

.tablet-styles{
    display: flex;

}

.container-flex > h3{
    margin-top: 30%;
    text-align: center;
    color: #111818;
    font-size: 28px;
    font-weight: 400;
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
    -webkit-text-decoration-color: #2E704E;
            text-decoration-color: #2E704E;
    text-underline-offset: 2px;
}


.border-style {
    border-radius: 50px !important;
    margin: 0;
}

.styles_c__2JQH2{
    /* margin-top: 20vh; */
}

@media (min-width: 65em) {
    .styles_c__1d5Tj{
        display:none;
    }
    .styles_c__1wf9-{
        display:none;
    }
    
    .styles_c__2JQH2{
        display:none;
    }

    .styles_c__6saF9{
        display:none;
    }
    .styles_c__3cqBa{
        display:none;
    }

    .demo__style{
        font-size: 18px;
        padding: 4px;
        border: 1px solid rgb(255, 254, 254);
        border-radius: 3px;
    }
    


    .container-flex > h3{
        margin-top: 10%;
    }
    .phone-card{
        display: none;
    }


    /* ***********************DO NOT REMOVE THIS(**************************/
    .tablet-styles{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        grid-gap: 2em;
        gap: 2em;
        align-content: stretch;
        margin-top: 5%;
    }
   
    /* ***********************DO NOT REMOVE THIS(**************************/

    .footer-section{
        margin-top: 10%;
    }
}



@media (max-width: 30em) {
    .tablet-styles{
        display: none;
    }
    .styles_c__1wf9-{
        /* margin-top: 52vh; */
        margin-bottom: -23vh;
    }
    
}


@media (min-width: 31em) and (max-width:48em){
    
    .phone-card{
        display: none;
    }
    .tablet-styles{
        display: flex;
        margin-top: 10%;
        flex-wrap: wrap;
        width: 95vw;
    }
    .footer-section{
        margin-top: 45%;
    }
    
}

@media (min-width: 49em) and (max-width: 64em) {


    
    .container-display{
        display: none;
    }
    .phone-card{
        display: none;
    }
    .tablet-styles{
        display: flex;
        margin-top: 10%;
    }


    .footer-position{
        position: absolute;
        bottom: 0;
        width: 85%;
    }
}

.kingtrader__mainContainer > *{
    background-color: #fff;
}

.kingtrader__mainContainer{
    box-shadow: 2px 2px 2px rgb(167, 167, 167);
    border-radius: 25px;
    background-color: #fff;
    height: 588px;
    width: 316px;
    z-index: 0;
}

.kingtrader__ShapeGradient{
    background: linear-gradient(170deg, rgba(46,112,78,0.7931547619047619) 0%, rgba(255,255,255,1) 100%);
    height: 250px;
    width: 316px;
    margin: 0;
    overflow: hidden;
    position: absolute;
    border-radius: 25px;
    z-index: -1;
}

.cut {
    background: #fff;
    position:absolute;
    top: 170px;
    left: -120px;
    width: 700px;
    height: 300px;
    transform-origin: center top;
    transform: rotate(36deg);
    z-index: -1;
  }

.kingtrader__SampleImage{
    background-color: transparent;
    height: 135px;
    width: 185px;
    margin-left: 30px;
    margin-top:20px;
}

.kingtrader__title{
    margin: 0;
    margin-top: 11px;
    top: 0;
    text-align:center;
    font-size: 24px;
    font-weight: 400;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 4px;
    z-index: 10;
    background-color: transparent;
}

.kingtrader__description{    
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
}

.kingtrader__tools > *{
    background-color: #fff;
}

.kingtrader__tools{
    margin: 0;
    margin-top: 35px;
}

.kingtrader__toolTitle{
    font-size: 16px;
    margin-left: 20px;
    font-weight: 300;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 2px;
}

.kingtrader__toolIcons{
    margin-left: 20px;
    margin-right: 20px;
}

.kingtrader__toolIcons > * {
    margin-bottom: 10px;
    margin-right: 20px;
    width: 40px;
    background-color: #fff;
}

.kingtrader__demoLink{
    display: flex;
    justify-content: center;
}

.kingtrader__demoButton{
    height: 37px;
    width: 121px;
    background-color: #479e71;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    margin-top:15px;
}
.thePotterSaga__mainContainer > * {
    background-color: #fff;
}

.thePotterSaga__mainContainer{
    box-shadow: 2px 2px 2px rgb(167, 167, 167);
    border-radius: 25px;
    background-color: #fff;
    height: 588px;
    width: 316px;
    z-index: 0;
}

.thePotterSaga__ShapeGradient{
    background: linear-gradient(170deg, rgba(250,208,69,1) 0%, rgba(255,255,255,1) 100%);
    height: 250px;
    width: 316px;
    margin: 0;
    overflow: hidden;
    position: absolute;
    border-radius: 25px;
    z-index: -1;
}

.cut-1 {
    background: #fff;
    position:absolute;
    top: 170px;
    left: -223px;
    width: 700px;
    height: 300px;
    transform-origin: center top;
    transform: rotate(57deg);
    z-index: -1;
  }
  .cut-2 {
    background: #fff;
    position:absolute;
    top: 170px;
    left: -159px;
    width: 700px;
    height: 300px;
    transform-origin: center top;
    transform: rotate(301deg);
    z-index: -1;
  }



.thePotterSaga__SampleImage{
    background-color: transparent;
    height: 135px;
    width: 185px;
    margin-left: 30px;
    margin-top:20px;
}


.thePotterSaga__title{
    margin: 0;
    margin-top: 11px;
    top: 0;
    text-align:center;
    font-size: 24px;
    font-weight: 400;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 4px;
    z-index: 10;
    background-color: transparent;
}

.thePotterSaga__description{    
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
}

.thePotterSaga__tools > *{
    background-color: #fff;
}

.thePotterSaga__tools{
    margin: 0;

}

.thePotterSaga__toolTitle{
    margin-top: 100px;
    font-size: 16px;
    margin-left: 20px;
    font-weight: 300;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 2px;
}

.thePotterSaga__toolIcons{
    margin-left: 20px;
    margin-right: 20px;
}

.thePotterSaga__toolIcons > * {
    margin-bottom: 10px;
    margin-right: 20px;
    width: 40px;
    background-color: #fff;
}

.thePotterSaga__demoLink{
    display: flex;
    justify-content: center;
}

.thePotterSaga__demoButton{
    height: 37px;
    width: 121px;
    background-color: #479e71;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    margin-top:15px;
}
.pt__mainContainer > *{
    background-color: #fff;
}

.pt__mainContainer{
    box-shadow: 2px 2px 2px rgb(167, 167, 167);
    border-radius: 25px;
    background-color: #fff;
    height: 588px;
    width: 316px;
    z-index: 0;
}

.pt__shapeGradient{
    margin: 0;
    background: linear-gradient(170deg, rgb(115, 158, 184) 0%, rgba(255,255,255,1) 100%);
    height: 209px;
    width: 316px;
    border-radius: 25px 25px 100% 100%;
    position: absolute;
    z-index: -1;
}

.pt__SampleImage{
    background-color: transparent;
    height: 135px;
    width: 185px;
    margin-left: 30px;
    margin-top:20px;
}

.pt__title{
    margin: 0;
    margin-top: 11px;
    top: 0;
    text-align:center;
    font-size: 24px;
    font-weight: 400;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 4px;
    z-index: 10;
    background-color: transparent;
}

.pt__description{    
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
}

.pt__tools > *{
    background-color: #fff;
}

.pt__tools{
    margin: 0;
}

.pt__toolTitle{
    font-size: 16px;
    margin-left: 20px;
    font-weight: 300;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    text-underline-offset: 2px;
}

.pt__toolIcons{
    margin-left: 20px;
    margin-right: 20px;
}

.pt__toolIcons > * {
    margin-bottom: 10px;
    margin-right: 20px;
    width: 40px;
    background-color: #fff;
}

.pt__demoLink{
    display: flex;
    justify-content: center;
}

.pt__demoButton{
    height: 37px;
    width: 121px;
    background-color: #479e71;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    margin-top:15px;
}
.about-container{
    margin-top: 35%;
    display: flex;
    flex-direction:column;
    align-content: space-between;
}

.image-div{
    display: flex;
    justify-content:center;
}

.fs-color{
    color:#2E704E;
}

.bio-div{
    margin-left: 0;
    margin-top: 10%;
}

.bio-div h3{
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    -webkit-text-decoration-color: #2E704E ;
            text-decoration-color: #2E704E ;
    text-underline-offset: 2px;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10%;
}

.bio-div p{
    font-size: 20px;
    font-weight: 300;
}

.skills-div{
    margin-left: 0;
    margin-top: 10%;
    margin-bottom: 5%;
}

.skills-div h3{
    font-size: 24px;
    font: 400;
    -webkit-text-decoration: underline 1px;
            text-decoration: underline 1px;
    -webkit-text-decoration-color: #2E704E ;
            text-decoration-color: #2E704E ;
    text-underline-offset: 2px;
    text-align: center;
    animation: bounce 1s ;
    position: relative;

}

@keyframes bounce {
    0%{
        transform:translateY(-100%);
        opacity: 0;    
    }
    5% {
        transform:translateY(-100%);
        opacity: 0;
    }
    15% {
        transform:translateY(0);
        padding-bottom: 5px;
      }
    30% {
        transform:translateY(-50%);
    }
    40% {
        transform:translateY(0%);
        padding-bottom: 6px;
    }
    50% {
        transform:translateY(-30%);
      }
    70% {
        transform:translateY(0%);
        padding-bottom: 7px;
    }
    80% {
      transform:translateY(-15%);
    }
    90% {
      transform:translateY(0%);
      padding-bottom: 8px;
    }
    95% {
      transform:translateY(-7%);
    }
    97% {
      transform:translateY(0%);
      padding-bottom: 9px;
    }
    99% {
      transform:translateY(-3%);
    }
    100% {
      transform:translateY(0);
      padding-bottom: 9px;
      opacity: 1;
    }

}

.skills-div > ul > li{
    margin-left: 0;
}

.skills-list{
    margin-left: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 2em;
    gap: 2em;
}

.spacing{
    margin-bottom: 20px;
}

.skills-list > li{
    width: 145px;
    display: flex;
}

.icon-style{
    height: 60px; 
    margin-right: 4%;
}

.bootstrap-style{
    width: 70px;
    height: 70px;
}


@media (min-width: 65em) {
    .about-container{
        margin-top: 10%
    }
    .profile-image{
        height: 350px;
    }
    .desktop-position{
        display: flex;
        flex-direction: row;
        align-items: center;
    }


    .bio-div{
        margin-top: 5%;
    }

    .bio-div > h3{
        margin-bottom: 5%;
        font-size: 32px;
        width: 50%;
        margin-left: 5%;
    }

    .bio-div > p{
        font-size: 24px;
        margin-left: 5%;
        
    }
    .tablet-tools{
        display: flex;
        justify-content: center;
    }

    .skills-div{
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .skills-list{

        margin-left: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 2em;
        grid-gap: 4.5em;
        gap: 4.5em;
        width: 50%;
    }
}

@media (min-width: 31em) and (max-width:64em){
    .about-container{
        margin-top: 25%
    }
    .profile-image{
        height: 350px;
    }

    .bio-div{
        margin-top: 5%;
    }

    .bio-div > h3{
        margin-bottom: 5%;
        font-size: 32px;
    }

    .bio-div > p{
        font-size: 24px;
    }
    .tablet-tools{
        display: flex;
        justify-content: center;
    }

    .skills-div{
        margin-top: 5%;
    }

    .skills-list{
        margin-left: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 2em;
        grid-gap: 4.5em;
        gap: 4.5em;
    }
}
.higher-div{
    margin-top: 25%;
    margin-left: 0;
    height: 90vh;
}

.contact-message{
    font-size: 18px;
    font-weight: 300;
}

.contact-me-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:10%;

}

.input{
    margin-top: 15%;
    font-size: 24px;
    border:0;
    border-bottom: 1px solid #2E704E;
    width: 100%;

}

.message-input{
    margin-top: 7%;
    height: 250px;
    width: 100%;
    font-size: 24px;
    font-weight: 300;
    resize: none;
    border: 1px solid #2E704E;
}



.submit-btn{
    margin-top: 5%;
}

@media (max-width: 30em) {


}



@media (min-width:65em) {
    .higher-div{
        display: flex;
        justify-content: space-evenly;
        margin-top: 15%;
    }
    .contact-message{
        font-size: 24px;
        width: 40%;
    }
    .contact-me-form{
        margin-top: 0;
        width: 70%;
    }
    .input{
        margin-top: 15%;
        width: 100%;
    }
    .name-input {
        margin-top: -15%;
    }
    .message-input{
        border: 1px solid #2E704E;
    }

    .message-input:active{
        border-color: #2E704E;
    }

    .submit-btn{
        margin-top: 5%;
    }

    .contact-message{
        width: 100%;
    }

    .name-input{
        margin-top: 7%;
    }

}

@media (min-width: 31em) and (max-width:64em){
    .contact-message{
        font-size: 24px;
        margin-left: 4%;
    }
    .contact-me-form{
        margin-top: 0;
        margin-left: 5%;
        margin-right: 4%;
    }
    .input{
        margin-top: 20%;
    }
    .submit-btn{
        margin-top: 5%;
    }
}
