

.footer-section{
    display: flex;
    flex-direction: column;
    margin: 5% 2% 0 2%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;
    z-index: 0;
    background-color: #EDEEF0;
}


.linkedIn-footer{
    height: 40px
}

.github-footer{
    height: 40px

}

.resume-image-footer{
    height: 40px
}

.div-footer-list{
    margin-bottom: 0;
}

.footer-list{
    margin-bottom: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-list > li > a{
    color: #111818;
    font-size: 18px;
    font-weight: bold;
}

.profile-links{
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 30em) {
    .footer-section{
        display: none;
    }
}