
.buyback__mainContainer > *{
    background-color: #fff;
}

.buyback__mainContainer{
    box-shadow: 2px 2px 2px rgb(167, 167, 167);
    border-radius: 25px;
    background-color: #fff;
    height: 588px;
    width: 316px;
    z-index: 0;
}

.buyback__ShapeGradient{
    margin: 0;
    margin-left: 5px;
    background: linear-gradient(170deg, rgba(245,124,1,1) 0%, rgba(255,255,255,1) 100%);   
    height: 209px;
    width: 311px;
    z-index: -1;
    border-radius: 20px 25px 0 100%;
    position: absolute;

}

.buyback__SampleImage{
    background-color: transparent;
    height: 135px;
    width: 185px;
    margin-left: 30px;
    margin-top:20px;
}

.buyback__title{
    margin: 0;
    margin-top: 11px;
    top: 0;
    text-align:center;
    font-size: 24px;
    font-weight: 400;
    text-decoration: underline 1px;
    text-underline-offset: 4px;
    z-index: 10;
    background-color: transparent;
}

.buyback__description{    
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
}

.buyback__tools > *{
    background-color: #fff;
}

.buyback__tools{
    margin: 0;
}

.buyback__toolTitle{
    font-size: 16px;
    margin-left: 20px;
    font-weight: 300;
    text-decoration: underline 1px;
    text-underline-offset: 2px;
}

.buyback__toolIcons{
    margin-left: 20px;
    margin-right: 20px;
}

.buyback__toolIcons > * {
    margin-bottom: 10px;
    margin-right: 20px;
    width: 40px;
    background-color: #fff;
}

.buyback__demoLink{
    display: flex;
    justify-content: center;
}

.buyback__demoButton{
    height: 37px;
    width: 121px;
    background-color: #479e71;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    margin-top:15px;
}