
.kingtrader__mainContainer > *{
    background-color: #fff;
}

.kingtrader__mainContainer{
    box-shadow: 2px 2px 2px rgb(167, 167, 167);
    border-radius: 25px;
    background-color: #fff;
    height: 588px;
    width: 316px;
    z-index: 0;
}

.kingtrader__ShapeGradient{
    background: linear-gradient(170deg, rgba(46,112,78,0.7931547619047619) 0%, rgba(255,255,255,1) 100%);
    height: 250px;
    width: 316px;
    margin: 0;
    overflow: hidden;
    position: absolute;
    border-radius: 25px;
    z-index: -1;
}

.cut {
    background: #fff;
    position:absolute;
    top: 170px;
    left: -120px;
    width: 700px;
    height: 300px;
    transform-origin: center top;
    transform: rotate(36deg);
    z-index: -1;
  }

.kingtrader__SampleImage{
    background-color: transparent;
    height: 135px;
    width: 185px;
    margin-left: 30px;
    margin-top:20px;
}

.kingtrader__title{
    margin: 0;
    margin-top: 11px;
    top: 0;
    text-align:center;
    font-size: 24px;
    font-weight: 400;
    text-decoration: underline 1px;
    text-underline-offset: 4px;
    z-index: 10;
    background-color: transparent;
}

.kingtrader__description{    
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
}

.kingtrader__tools > *{
    background-color: #fff;
}

.kingtrader__tools{
    margin: 0;
    margin-top: 35px;
}

.kingtrader__toolTitle{
    font-size: 16px;
    margin-left: 20px;
    font-weight: 300;
    text-decoration: underline 1px;
    text-underline-offset: 2px;
}

.kingtrader__toolIcons{
    margin-left: 20px;
    margin-right: 20px;
}

.kingtrader__toolIcons > * {
    margin-bottom: 10px;
    margin-right: 20px;
    width: 40px;
    background-color: #fff;
}

.kingtrader__demoLink{
    display: flex;
    justify-content: center;
}

.kingtrader__demoButton{
    height: 37px;
    width: 121px;
    background-color: #479e71;
    padding: 10px;
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    margin-top:15px;
}