

.main-container{
    margin-top: 23%;
    z-index: 2; 
}

.fs-style{
    color:#2E704E;
}

.first-container{
    margin-left: -1%;
}

.first-container h2{
    font-size: 30px;
    font-weight: 300;
}

.first-container > h3{
    font-size: 24px;
    font-weight: 300;
}

.image-container{
    margin-top: 7%;
    display: flex;
    justify-content: center;
}

.profile-image{
    border-radius: 50%;
    height: 275px;
}

.contact-me-form{
    height: 77vh;
}


.button-position{
    display:flex;
    justify-content: center;
    margin: 12%;
}

.contact-btn{
    box-shadow: 2px 2px 2px rgb(167, 167, 167);
    background-color: #2E704E;
    height: 68px;
    width: 200px;
    border: 0;
    border-radius: 0 20px 0 20px;
    font-size: 24px;
    color: #EDEEF0;
}

.last-container > p{
    margin-top: 10%;
    font-size: 22px;
    font-weight: 300;
}


/* MEDIA IS A COMPUTER*/

@media (min-width:64em){


    .main-container{
        margin-top: 5%;
    }

    .sub-container{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-evenly;
    }
    .sub-container > .first-container{
        margin-left: 3%;
    }

    .last-container > p{
        margin-top: 5%;
        font-size: 25px;
        width: 100%;
    }

    .button-position{
        margin: 5%;
    }

    .recent-work{
        margin-top: 10%;
    }
    .inner-recent-work{
        display: flex;
        justify-content: space-evenly;
    }
    .recent-work > h3{
        font-size: 28px;
        color: #111818;
        text-decoration: underline;
        text-decoration-color: #2E704E;
        text-underline-offset: 2px;
        text-align: center;
        margin-bottom:5%;
    }

    .profile-image{
        height: 350px;
    }

    .card-img{
        height:180px;
    }

    .main-card-style{
        width: 328.5px;
        margin-left: 2%;
    }



    .buyback-card > p{
        font-size: 32px;
        margin-top: -2%;
        padding-top: 2%;
        width: 100%;
        text-align: center;
        background-color: #2E704E;
    }


    .buyback-card > div{
        background-color: #2E704E;
        width: 100%;
        padding-top: 5%;
        margin-top:-1%;
        margin-right: 3px;
        color: #EDEEF0;
        height: 133px;
    }

    .buyback-card > div > p{
        background-color: #2E704E;
        margin: 2%;
    }


    .buyback-card > p{
        background-color: #2E704E;
        color: #EDEEF0;
        margin-bottom: 0;
    }  
 
    
    
    .kingtrader-card > p{
        background-color: #2E704E;
        color: #EDEEF0;
    }
    .buyback-tools{
        width: 320px;
    }

    .buyback-tools > h3{
        background-color: #2E704E;
        font-size: 22px;
        font-weight: 300;
        margin-left: 2%;

    }

   
    .lower-card{
        background-color: #2E704E;
    }

    .home-section{
        height: 100px;
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        padding: 2%;
    }

    .kt-home-section{
        height: 100px;
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
    }

    .home-styles{
        background-color: #2E704E;
        height: 40px;
    }

    .first-container > h3{
        font-size: 26px;
    }

    

}



/* MEDIA LESS THAN A PHONE SIZE */

@media (max-width: 30em) {
    .recent-work{
        display: none;
    }
}


/* MEDIA REGULAR TABLET */

@media (min-width: 31em) and (max-width:48em){



    .styles_c__1wf9-{
        display:none;
    }
    
    .styles_c__2JQH2{
        display:none;
    }

    .styles_c__6saF9{
        display:none;
    }

    .main-container{
        margin-top:15%;
    }

    .sub-container{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .sub-container > .first-container{
        margin-left: 3%;
    }

    .last-container > p{
        margin-top: 5%;
        font-size: 25px;
        width: 100%;
    }

    .button-position{
        margin: 5%;
    }

    .recent-work{
        margin-top: 10%;
    }
    .inner-recent-work{
        display: flex;
        justify-content: space-between;
    }
    .recent-work > h3{
        font-size: 28px;
        color: #111818;
        text-decoration: underline;
        text-decoration-color: #2E704E;
        text-underline-offset: 2px;
        text-align: center;
        margin-bottom:5%;
    }

    .card-img{
        height:173.5px;
    }

    .main-card-style{
        width: 316px;
        margin-left: 2%;
        margin-top: 3vh;
    }

    .first-container > h3{
        font-size: 26px;
    }

   


}


@media (min-width: 49em) and (max-width:64em){


    .main-container{
        margin-top:15%;
    }

    .sub-container{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    .sub-container > .first-container{
        margin-left: 3%;
    }

    .last-container > p{
        margin-top: 5%;
        font-size: 25px;
        width: 100%;
    }

    .button-position{
        margin: 5%;
    }

    .recent-work{
        margin-top: 10%;
    }
    .inner-recent-work{
        display: flex;
        justify-content: space-between;
    }
    .recent-work > h3{
        font-size: 28px;
        color: #111818;
        text-decoration: underline;
        text-decoration-color: #2E704E;
        text-underline-offset: 2px;
        text-align: center;
        margin-bottom:5%;
    }

}